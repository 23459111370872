function isFullyVisible(element) {
  const rect = element.getBoundingClientRect();
  return rect.width < window.innerWidth;
}

function pauseOnFullVisibility() {
  const isPartiallyVisible = (element) => {
    const rect = element.getBoundingClientRect();
    return rect.top < window.innerHeight && rect.bottom > 0;
  };

  // Clone the scrolling items
  const scrollingElements = document.querySelectorAll('.scrolling');
  scrollingElements.forEach((scrollingElement) => {
    const scrollingItem = scrollingElement.querySelector('.scrolling-items__item');
    const clonedItem = scrollingItem.cloneNode(true);
    scrollingItem.after(clonedItem);
  });

  // Pause the animation when elements are fully visible
  const scrollingContainer = document.querySelectorAll('.scrolling__items');
  scrollingContainer.forEach((thisScrollingContainer) => {
    thisScrollingContainer.addEventListener('animationstart', function() {
      if (isFullyVisible(thisScrollingContainer)) {
        thisScrollingContainer.style.animationPlayState = 'paused';
      } else {
        thisScrollingContainer.style.animationPlayState = 'running';
      }
    });

    // Start animations if elements are partially visible
    if (isPartiallyVisible(thisScrollingContainer)) {
      thisScrollingContainer.style.animationPlayState = 'running';
    }
  });
}

// Execute the function after the DOM content is loaded
window.addEventListener('DOMContentLoaded', () => {
  pauseOnFullVisibility();
});
